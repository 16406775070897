import React from 'react'
import moment from 'moment'
import { Layout, Breadcrumbs } from '@common'
import { AttentionSection, BlogSection } from '@sections'
import './styles/post.scss'

const Post = ({ pageContext }) => {
	const { page, posts } = pageContext
	return (
		<Layout
			subtitle={moment(page.date).format('DD / MM / YYYY')}
			seo={pageContext.page.seo}
  			siteMetaData={pageContext.page.siteMetaData}
		>
			<AttentionSection  
				title={page.title}
				image={page.featuredImage}
			/>

			<div className='post'>
				<div className='post-content'>
					<div className='container'>
						<div className='row'>
							<div className='col' />
						</div>
					</div>
					<div className='container'>
						<div className='row justify-content-center'>
							<div className='col-12 col-md-8'>
								<Breadcrumbs
									elements={[
										{ label: 'Blog', url: '/blog/' },
										{
											label: page.title,
											url: `/blog/${page.slug}/`,
										},
									]}
								/>
								<div
									className='post-inner'
									dangerouslySetInnerHTML={{
										__html: page.content,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<BlogSection posts={posts} slider={true}/>
		</Layout>
	)
}

export default Post
